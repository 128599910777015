<template>
  <!-- <div>图片查询</div> -->
  <el-container class="ImageQuery">
    <el-header height="auto">
      <div class="filter-pane reportTree">
        <label>车组车辆</label>
        <SelectGroupTree
          style="width: 220px;"
          @on-select="onSelect"
          @on-vehicle="onConfirm"
          :multipleProp="{
            noGroup: true, // 是否可选车组
            groupCheckBox: false, //车组多选
            vehicleCheckBox: false, //车辆多选
          }"
          @dbClick="dbConfirm"
          :defaultCheck="false"
          class="TreeWidth"
          @clear="ClearSearchParams"
        ></SelectGroupTree>
        <label style="margin-left:5px">选择时间</label>
        <reportDate picker size="small" @change="onChoiceDate"></reportDate>
        <!-- <label style="width:45px">标签：</label>
        <el-select
          style="width:80px"
          size="small"
          v-model="searchParams.labelId"
          placeholder="请选择"
        >
          <el-option label="全部" value></el-option>
          <el-option
            v-for="item in tagList"
            :key="item.value"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>-->
        <el-button
          size="mini"
          type="primary"
          style="font-size: 14px;height:32px;margin-left:20px;"
          :disabled="tableLoading"
          @click="onSearchClick"
        >搜索</el-button>
      </div>
    </el-header>
    <el-main class="reportTable">
      <el-tabs v-model="activeName" style="height:calc(100% - 45px);">
        <el-tab-pane label="列表" name="list">
          <el-table size="mini" stripe border :data="data" height="100%">
            <el-table-column
              type="index"
              width="60"
              label="序号"
              :index="indexRender"
            ></el-table-column>
            <el-table-column prop="cTime" width="150" label="拍摄时间"></el-table-column>
            <el-table-column prop="imageName" min-width="220" label="名称"></el-table-column>
            <el-table-column prop="eventType" width="100" label="类型"></el-table-column>
            <el-table-column prop="plate" width="120" label="车牌"></el-table-column>
            <el-table-column prop="plateColor" width="80" label="车牌颜色"></el-table-column>
            <el-table-column prop="terminalNo" width="120" label="终端编号"></el-table-column>
            <el-table-column prop="terminalType" width="100" label="终端类型"></el-table-column>
            <el-table-column prop="speed" width="80" label="速度"></el-table-column>
            <el-table-column width="80" label="通道">
              <template slot-scope="scope">
                <span>通道 {{scope.row.channel}}</span>
              </template>
            </el-table-column>
            <el-table-column width="100" label="操作">
              <template slot-scope="scope">
                <el-button
                  :disabled="isImg(scope.path)"
                  type="text"
                  @click="onDetailClick(scope)"
                >查看</el-button>
                <el-button type="text" @click="onDownloadClick(scope)">下载</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="address" width="400" label="地址"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="图片预览" name="preview">
          <div class="preview">
            <ImageCard
              class="card"
              v-for="(item, index) in data"
              :key="item.index"
              :lazy="index > 15"
              :iProps="item"
            ></ImageCard>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-pagination
        background
        :current-page="pageObj.current"
        :page-size="pageObj.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="pageObj.total"
        @current-change="onPageCurrentChange"
      ></el-pagination>
    </el-main>
    <PictureDialog v-model="pictureDialog.modal" :iProps="pictureDialog.props"></PictureDialog>
  </el-container>
</template>

<script>
//选择时间
import reportDate from "@/components/Date/reportDate";
//选择车组
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
//搜索接口
import { getPhotograph } from '@/api/All-Interface.js';


import { getAddresses } from '@/components/AMap/utils';

import { pictureShow, getLabels } from "@/api/getReport.js";
import { saveAsFile } from '@/utils/saveAsFile';
//查看图片子组件
import PictureDialog from "./PictureDialog";
import ImageCard from "./PicturePreview";
export default {
  // `name`与路由保持一致
  name: 'ImageQuery',
  components: {
    reportDate,
    SelectGroupTree,
    PictureDialog,
    ImageCard,
  },
  data() {
    return {
      activeName: 'list',
      pictureDialog: { //列表 - 点击查看
        modal: false,
        props: {}
      },
      searchParams: {
        vehicleId: 0,
        groupId: 0,
        flag: 0,
        beginTime: '',
        endTime: '',
        pageSize: 20,
        pageNumber: 1,
        labelId: '',//标签id
      },
      data: [],//数据集合
      dates: "",//选择时间
      tableLoading: false,//用于加载数据时的等待状态
      // tagList: [],//标签，一开始就通过接口来获取
      pageObj: {
        current: 1,
        pageSize: 20,
        total: 0
      },
    };
  },
  methods: {
    onChoiceDate(dates) {//选择时间
      this.searchParams.beginTime = '';
      this.searchParams.endTime = '';
      if (dates.length === 0) return this.$message.warning('请选择正确的时间');
      if (dates.length === 2) {
        this.searchParams.beginTime = dates[0];
        this.searchParams.endTime = dates[1];
      }
      this.pageObj.current = 1;
      this.pageObj.total = 0;
    },
    //车组车辆搜索框选择
    onSelect(node) {
      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.groupId = node.groupId || 0;
      if (node.type == 2) {
        this.searchParams.flag = 1;
      } else if (node.type == 1) {
        this.searchParams.flag = 0;
      }
    },
    // 选择车辆事件
    onConfirm(node) {
      //先进行清空
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;

      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.flag = 1;
      this.searchParams.groupId = node.groupId || 0;
    },
    // 选择车组
    dbConfirm(node) {
      this.searchParams.pageNumber = 1;//重新选择车组车辆时间时，页数重置
      this.searchParams.flag = 0;
      this.searchParams.groupId = node.groupId;
      this.searchParams.vehicleId = "";
    },
    //点击清除时，初始化车组车辆参数
    ClearSearchParams() {
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;
    },
    //序号调整
    indexRender(index) {
      return (this.pageObj.current - 1) * this.pageObj.pageSize + index + 1;
    },
    //分页改变每页数量
    onPageCurrentChange(current) {
      this.pageObj.current = current;
      this.onSearchClick();
    },
    //验证是否有输入车组或车辆
    verification() {
      if (this.searchParams.groupId == "" || this.searchParams.groupId == 0) {
        this.$message.info("请输入或者选择车组车辆");
        return false;
      } else {
        return true;
      };
    },
    //点击搜索
    onSearchClick() {
      this.data = [];//清空
      if (!this.verification()) return;//验证是否有输入车组或车辆
      this.tableLoading = false;//开启等待转圈圈
      getPhotograph({
        ...this.searchParams,
        pageNumber: this.pageObj.current,
        pageSize: this.pageObj.pageSize
      }).then((res) => {
        this.tableLoading = false;//关闭等待转圈圈
        if (!res.rows || res.rows.length == 0) return this.$message.warning("数据为空！");
        if (res.flag == 1) {
          this.pageObj.total = res.total;
          let resData = res.rows.map((item, index) => ({
            ...item,
            index: this.indexRender(index),
            address: '',
          }));

          this.data = resData;

          //地址转换
          let posList = res.rows.map((p, i) => ({
            lon: p.lon,
            lat: p.lat,
            tag: i
          })).filter(obj => obj.lon && obj.lat);

          //在此进行经纬度转换
          getAddresses(posList).then(result => {
            result.forEach(({ tag, address }) => {
              resData[tag * 1].address = address;
            });
            // resData.filter(p => this.isImg(p.path));
          });
        } else {
          return this.$message.warning("数据为空！");
        }
      });
    },
    //列表 - 查看
    onDetailClick(scope) {
      this.pictureDialog.props = { ...scope.row };
      this.pictureDialog.modal = true;//开启查看图片的弹窗
    },
    //列表 - 下载
    onDownloadClick(scope) {
      this.downloadImg(scope.row.path, scope.row.imageName);
    },
    //下载
    downloadImg(url, name) {
      pictureShow({
        path: url
      }).then(res => {
        if (res.size > 0) {
          let href = URL.createObjectURL(res);
          saveAsFile(href, name);
        } else {
          this.$message.error("未能下载图片！");
        }
      });
    },
    //验证数据是否是图片
    isImg(filename) {
      return (
        filename &&
        [".png", ".jpg", ".jpeg", ".gif"].some(suffix =>
          filename.toLowerCase().endsWith(suffix)
        )
      );
    },

    //获取标签选项：抽烟，超载等
    // getLabels() {
    //   getLabels().then(res => {
    //     this.tagList = res.obj;
    //     if (!(res.flag && res.obj.length)) {
    //       return this.$message.warning(res.msg);
    //     }
    //   });
    // },

  },
  mounted() {
    // this.getLabels();//进来时就加载标签选项
  }
}
</script> 
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
@import 'src/style/reportTreeAndData.scss';
.ImageQuery {
  height: 100%;
  .el-header {
    margin: 0;
    padding: 0;
  }
  .el-main {
    height: calc(100% - 48px) !important;
    padding: 0px 20px 0 20px;
    ::v-deep .el-tabs {
      .el-tabs__content {
        height: calc(100% - 55px);
        #pane-list {
          height: 100%;
        }
      }
    }
  }
  .preview {
    height: 73vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .card {
    // width: calc(100% / 4 - 20px);
    width: 387px;
    margin: 0 20px 20px 0;
    height: 505px;
    display: inline-block;
    font-size: 14px;
  }
  .el-pagination {
    padding: 12px 5px 5px;
    text-align: center;
  }
}
</style>