<template>
  <el-card
    :body-style="{ padding: '0px', height:'100%' }"
    shadow="never"
    class="box-card"
  >
    <el-image fit :lazy="lazy" :src="imgSrc" class="image-outer">
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <div class="info-bar-header">
      <div class="row" style="position: relative;margin:0 0 5px 0">
        <div class="index_outline">NO. {{iProps.index}}</div>
        <el-tag type="success" class="Tube">通道{{iProps.channel}}</el-tag>
        <el-tag
          v-if="iProps.labelNames"
          closable
          size="mini"
          class="labelTag"
          @close="handleClose"
        >{{iProps.labelNames}}</el-tag>
        <!-- <el-select
          v-else
          v-model="inputValue"
          class="PoctureElSelect"
          placeholder=""
          @change="saveTag"
        >
          <div slot="prefix">
            <span class="el-icon-plus prefixSpan">标签</span>
          </div>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select> -->
        <div @click="onDownload" class="oper_bar">下载</div>
      </div>
    </div>
    <div class="info-bar">
      <div class="row">
        <div class="title">车牌</div>
        <div class="content">{{iProps.plate}}</div>
      </div>
      <div class="row">
        <div class="title">车组</div>
        <div class="content">{{iProps.groupName}}</div>
      </div>
      <div class="row">
        <div class="title">终端编号</div>
        <div class="content">{{iProps.terminalNo}}</div>
      </div>
      <div class="row">
        <div class="title">名称</div>
        <div class="content">{{iProps.imageName}}</div>
      </div>
      <div class="row">
        <div class="title">类型</div>
        <div class="content">{{iProps.eventType}}</div>
      </div>
      <div class="row">
        <div class="title">拍摄时间</div>
        <div class="content">{{iProps.cTime}}</div>
      </div>
      <div class="row">
        <div class="title">地址</div>
        <div class="content">{{iProps.address}}</div>
      </div>
    </div>
  </el-card>
</template>
<script>
import { saveAsFile } from '@/utils/saveAsFile';
import { pictureShow, saveLabels } from "@/api/getReport.js";

export default {
  name: 'PicturePreview',
  props: {
    iProps: {
      type: Object,
      default() {
        return {};
      }
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    // options: {
    //   type: Array,
    //   default: []
    // }
  },
  data() {
    return {
      imgSrc: '',
      // inputValue: '',
      // dynamicTags: ['标签一', '标签二', '标签三'],
    };
  },
  beforeMount() {
    this.showImg(this.iProps.path);
  },
  methods: {
    onDownload() {
      saveAsFile(this.imgSrc, this.iProps.imageName);
    },
    showImg(url) {
      pictureShow({
        path: url
      }).then(res => {
        if (res.size <= 0) return;
        this.imgSrc = URL.createObjectURL(res);
      }).catch(err=>{
        console.log(err);
      });
    },
    // saveTag() {
    //   const params = {
    //     imageLabels: [
    //       {
    //         imageId: this.iProps.imageId,
    //         labels: [
    //           {
    //             labelId: this.inputValue
    //           }
    //         ]
    //       }
    //     ]
    //   };
    //   let inValue = '';
    //   inValue = this.inputValue; //清空el-select 的值显示
    //   this.inputValue ="";
    //   saveLabels(params).then(res => {
    //     if (!res.flag) return this.$message.warning(res.msg);
    //     this.iProps.labelNames = (this.options.filter(p => p.id === inValue)[0]).label;
    //   });
    // },
    handleClose() {
      const params = {
        imageLabels: [
          {
            imageId: this.iProps.imageId,
            labels: []
          }
        ]
      };
      saveLabels(params).then(res => {
        if (!res.flag) return this.$message.warning(res.msg);
        this.iProps.labelNames = '';
      });
    },
    // handleInputConfirm() {
    //   let inputValue = this.inputValue;
    //   if (inputValue) {
    //     this.dynamicTags.push(inputValue);
    //   }
    //   this.inputValue = '';
    // }
  }
}
</script>

<style lang="scss" scoped>
.box-card{
  .image-outer {
    width: 100%;
    height: 230px;
  }
  .info-bar-header {
    padding: 10px 15px 4px 15px;
    line-height: 20px;
    background-color: #F2F2F2;
    position: relative;
    top: -3px;
    .index_outline {
      text-align: center;
      line-height: 24px;
      font-size: 13px;
      color:#262626;
      margin-left: 20px;
    }
    .oper_bar {
      cursor: pointer;
      position: absolute;
      right: 0px;
      color: #1890FF;
    }
    // .PoctureElSelect{
    //   padding: 0;
    //   width:60px;
    //   margin:0;
    //   position: relative;
    //   left: 20px;
    //   ::v-deep .el-input__suffix-inner{
    //     display: none;
    //   }
    //   ::v-deep .el-input__inner{
    //     padding: 0;
    //     height: 24px;
    //     border: 1px solid #1890FF;
    //   }
    //   .prefixSpan{
    //     display: inline-block;
    //     color: #1890FF;
    //     padding-left:7px ;
    //     font-size: 12px;
    //     width: 60px;
    //     height: 22px;
    //     line-height: 23px;
    //     position: absolute;
    //     top: 1px;
    //     left: -4px;
    //     text-align: left;
    //   }
    // }
    .Tube{
      margin-left: 10px;
    }
    .labelTag{
      width: 60px;
      height: 24px;
      line-height: 22px;
      padding-left: 10px;
      position: relative;
      left: 60px;
    }
  }
  .info-bar{
    height: 230px;
    padding: 10px 15px;
    line-height: 25px;
    border: 1px solid #e6e6e6;
    position: relative;
    top: -3px;
    .title {
      width: 65px;
      margin-right: 30px;
      text-align: end;
      color: #999999;
    }
    .content {
      width: calc(100% - 55px);
      color: #333333;
    }
  }
  .row {
    display: flex;
  }
}
</style>