<template>
  <div v-if="modal" class="dialog-wrapper">
    <el-dialog
      :visible="modal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
      custom-class="custom_outer"
      destroy-on-close
      append-to-body
      @opened="onOpendDialog"
      class="PictureElDialog"
    >
      <div slot="title" class="header">
        <span>查看图片</span>
        <span class="exit-icon" title="关闭" @click="onModalChange(false)">
          <i size="32" class="el-icon-close"></i>
        </span>
      </div>
      <div v-if="modal" class="modal-main">
        <preview
          :iProps="iProps"
          :options="options"
          style="height:100%;width:100%;"
        ></preview>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import Preview from './PicturePreview';
import { mapState } from 'vuex';

export default {
  name: 'DialogPictureBig',
  components: {
    Preview,
  },
  model: {
    prop: 'modal',
    event: 'modal-change'
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    iProps: { //输入参数
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  computed: {
  },
  watch: {
    modal: {
      // immediate: true,
      handler(val) {
        if (val) {
          //打开操作
        }
      }
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    onModalChange(modal, eventName = 'on-cancel', ...options) {
      this.$emit('modal-change', !!modal);
      this.$emit(eventName, ...options);
    },
    onOpendDialog() {

    },
    onSaveData() {
      this.onModalChange(false, 'on-ok');
    }
  }
}
</script>

<style lang="scss" scoped>
// .dialog-wrapper {
//   position: relative;
//   height: 0;
// }

// .el-dialog.custom_outer {
//   display: flex;
//   flex-direction: column;

//   .el-dialog__headerbtn {
//     top: 12px;
//   }
//   .el-dialog__header {
//     padding: 10px;
//     font-weight: bold;
//     border-bottom: 1px solid #e8eaec;
//     color: #909399;
//     font-size: 18px;
//   }

//   .el-dialog__footer {
//     padding: 10px 20px;
//     text-align: center !important;
//     border-top: 1px solid #e8eaec;
//   }

//   .el-dialog__body {
//     padding: initial;
//   }
// }

// .exit-icon {
//   position: absolute;
//   right: 0px;
//   top: 0px;
//   cursor: pointer;
//   width: 20px;
//   text-align: center;
// }
// .exit-icon:hover {
//   background-color: #d01212;
//   color: white;
// }

// .header {
//   position: relative;
// }

// .modal-main {
//   height: 520px;
//   font-size: 14px;
//   position: relative;
// }
// .bottom {
//   position: relative;
//   height: 35px;
//   width: 100%;
//   display: inline-flex;
//   justify-content: flex-end;
//   align-items: center;
//   & > button {
//     margin: 0 3px;
//   }
// }
.PictureElDialog {
  ::v-deep .el-dialog__header {
    background-color: #212d3e;
    padding: 10px 20px;
    .header {
      position: relative;
      font-size: 16px;
      color: #ffffff;
      .exit-icon {
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
        width: 20px;
        text-align: center;
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 13px;
  }
}
</style>

